@media only screen {
    .main {
        position: relative;
        height: 100vh!important;
        max-height: 100vh;
        overflow: auto!important;
    }

    .bar {
        z-index: 30!important;
        position: relative;
    }

    .baz {
        position: sticky;
         top: 0;
         box-shadow: 0 2px 8px gray
    }
}