:root {
    /* --test: rgb(0, 63, 62); */
    --primary-color-rgb: 0, 63, 62;
    --primary-color-rgb-light: 0, 88, 86;
    --primary-color-rgb-dark: 1, 50, 49;

    --light-primary-color: rgb(var(--primary-color-rgb));
    --light-primary-color-alpha: rgba(var(--primary-color-rgb), 0.443);
    --light-primary-color-light: rgb(var(--primary-color-rgb-light));
    --light-primary-color-dark: rgb(var(--primary-color-rgb-dark));

    --light-secondary-color: #aaa;
    --light-secondary-color-alpha: #aaaaaaa0;
    --light-secondary-color-light: #aaa;
    --light-secondary-color-dark: #aaa;

    --light-text-primary: var(--light-primary-color);
    --light-text-secondary: #aaa;

    --light-fg-color: #333;
    --light-fg-color-accent: #151515;
    --light-fg-color-highlight: #787878;

    --light-bg-color: #d8d8d8;
    --light-bg-color-accent: #c8c8c8;
    --light-bg-color-highlight: #f3f3f3;
    --light-bg-color-alpha: #d8d8d8f0;




    /* --------------- DARK --------------------------------- */



    --dark-primary-color: rgb(151, 5, 124);
    --dark-primary-color-alpha: rgba(151, 5, 124, 0.443);
    --dark-primary-color-light: rgb(184, 6, 151);
    --dark-primary-color-dark: rgb(114, 6, 95);

    --dark-secondary-color: #aaa;
    --dark-secondary-color-alpha: #aaa;
    --dark-secondary-color-light: #aaa;
    --dark-secondary-color-dark: #aaa;

    --dark-text-primary: rgb(151, 5, 124);
    --dark-text-secondary: #aaa;

    --dark-fg-color: #ccc;
    --dark-fg-color-accent: #909090;
    --dark-fg-color-highlight: #e0e0e0;

    --dark-bg-color: #161616;
    --dark-bg-color-accent: #0c0c0c;
    --dark-bg-color-highlight: #262626;
    --dark-bg-color-alpha: #16161690;
}

.hover-primary,
.hover-primary-light,
.hover-primary-dark {
    transition: background-color 300ms ease-out;
}

.text-hover {
    transition: color 300ms ease-out;
}

body {
    overflow: hidden;
}

main[data-theme=light] .text-primary {
    color: var(--light-primary-color) !important
}

main[data-theme=light] .btn-primary {
    background-color: var(--light-primary-color) !important;
    border-color: var(--light-primary-color-dark) !important;
}

main[data-theme=light] .btn-primary:hover {
    background-color: var(--light-primary-color-light) !important;
}

main[data-theme=dark] .btn-primary:hover {
    background-color: var(--dark-primary-color-light) !important;
}



main[data-theme=light] .dropdown-item.active {
    background-color: var(--light-primary-color-light) !important;
}

main[data-theme=dark] .dropdown-item.active {
    background-color: var(--dark-primary-color-light) !important;
}


main[data-theme=dark] .text-primary {
    color: var(--dark-primary-color) !important
}


main[data-theme=light] .bg-primary {
    background-color: var(--light-primary-color) !important
}

main[data-theme=light] .bg-primary-light {
    background-color: var(--light-primary-color-light)
}

main[data-theme=light] .bg-primary-dark {
    background-color: var(--light-primary-color-dark)
}

main[data-theme=light] .bg-primary-alpha {
    background-color: var(--light-primary-color-alpha)
}

main[data-theme=light] .bg-secondary {
    background-color: var(--light-secondary-color)
}

main[data-theme=light] .bg-secondary-light {
    background-color: var(--light-secondary-color-light)
}

main[data-theme=light] .bg-secondary-dark {
    background-color: var(--light-secondary-color-dark)
}

main[data-theme=light] .bg-secondary-alpha {
    background-color: var(--light-secondary-color-alpha)
}

main[data-theme=light] .fg-color {
    color: var(--light-fg-color)
}

main[data-theme=light] .fg-color-accent {
    color: var(--light-fg-color-accent)
}

main[data-theme=light] .fg-color-highlight {
    color: var(--light-fg-color-highlight)
}

main[data-theme=light] .bg-color {
    background-color: var(--light-bg-color)
}

main[data-theme=light] .bg-color-accent {
    background-color: var(--light-bg-color-accent)
}

main[data-theme=light] .bg-color-highlight {
    background-color: var(--light-bg-color-highlight)
}


main[data-theme=light] .text-primary {
    color: var(--light-primary-color)
}

main[data-theme=light] .text-secondary {
    color: var(--light-secondary-color)
}

main[data-theme=light] .text-hover-primary:hover {
    color: var(--light-primary-color) !important;
}

main[data-theme=light] .hover-primary:hover {
    background-color: var(--light-primary-color) !important;
}

main[data-theme=light] .hover-primary-light:hover {
    background-color: var(--light-primary-color-light) !important;
}

main[data-theme=light] .hover-primary-dark:hover {
    background-color: var(--light-primary-color-dark) !important;
}


/* ------------------------------DARK---------------------------------------- */


main[data-theme=dark] .bg-primary {
    background-color: var(--dark-primary-color)
}

main[data-theme=dark] .bg-primary-light {
    background-color: var(--dark-primary-color-light)
}

main[data-theme=dark] .bg-primary-dark {
    background-color: var(--dark-primary-color-dark)
}

main[data-theme=dark] .bg-primary-alpha {
    background-color: var(--dark-primary-color-alpha)
}

main[data-theme=dark] .bg-secondary {
    background-color: var(--dark-secondary-color)
}

main[data-theme=dark] .bg-secondary-light {
    background-color: var(--dark-secondary-color-light)
}

main[data-theme=dark] .bg-secondary-dark {
    background-color: var(--dark-secondary-color-dark)
}

main[data-theme=dark] .bg-secondary-alpha {
    background-color: var(--dark-secondary-color-alpha)
}

main[data-theme=dark] .fg-color {
    color: var(--dark-fg-color)
}

main[data-theme=dark] .fg-color-accent {
    color: var(--dark-fg-color-accent)
}

main[data-theme=dark] .fg-color-highlight {
    color: var(--dark-fg-color-highlight)
}

main[data-theme=dark] .bg-color {
    background-color: var(--dark-bg-color)
}

main[data-theme=dark] .bg-color-accent {
    background-color: var(--dark-bg-color-accent)
}

main[data-theme=dark] .bg-color-highlight {
    background-color: var(--dark-bg-color-highlight)
}


main[data-theme=dark] .text-primary {
    color: var(--dark-primary-color)
}

main[data-theme=dark] .text-secondary {
    color: var(--dark-secondary-color)
}


main[data-theme=dark] .text-hover-primary:hover {
    color: var(--dark-primary-color) !important;
}

main[data-theme=dark] .hover-primary:hover {
    background-color: var(--dark-primary-color) !important;
}

main[data-theme=dark] .hover-primary-light:hover {
    background-color: var(--dark-primary-color-light) !important;
}

main[data-theme=dark] .hover-primary-dark:hover {
    background-color: var(--dark-primary-color-dark) !important;
}


main[data-theme=dark] {
    background-color: var(--dark-bg-color);
    color: var(--dark-fg-color);
}

main[data-theme=light] {
    background-color: var(--light-bg-color);
    color: var(--light-fg-color);
}

html,
body,
#root {
    height: 100vh;
}


header {
    position: sticky;
    top: 0;
    z-index: 100;
}

.shadow {
    box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .15) !important;
}


aside .nav-toggle {
    outline: 0;
    border: 0;
    background-color: transparent;
    transition: background-color 300ms ease-out;
    /* min-width: 6rem; */
    text-decoration: none;
    font-size: .88rem;
}

main[data-theme=light] aside .nav-toggle.active,
main[data-theme=light] aside .nav-toggle:hover {
    background-color: var(--light-primary-color-dark);
}

main[data-theme=dark] aside .nav-toggle.active,
main[data-theme=dark] aside .nav-toggle:hover {
    background-color: var(--dark-primary-color-dark);
}

aside .nav-toggle .fa-play {
    position: absolute;
    right: .4rem;
    top: 50%;
    transform: translateY(-50%) scale(.4);
    opacity: .5;
}

/* main[data-theme=dark] aside .nav-toggle .fa-play{color: var(--dark-primary-color-light);} */
/* main[data-theme=light] aside .nav-toggle .fa-play{color: var(--light-primary-color-light);} */

aside .nav-toggle .nav-links {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: inherit;
    border-radius: 0 5px 5px 0;
    z-index: 200;
    overflow: hidden;
}

aside .nav-toggle .nav-links a {
    white-space: nowrap;
    min-width: 13rem;
}

.busy-component {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    font-size: 1.3rem;
}

main[data-theme=light] .busy-component {
    background-color: var(--light-bg-color-alpha);
    color: var(--light-fg-color);
}

main[data-theme=dark] .busy-component {
    background-color: var(--dark-bg-color-alpha);
    color: var(--dark-fg-color);
}


.component-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: fixed;
    padding: 1rem;
}

main[data-theme=light] .component-modal {
    background-color: var(--light-bg-color-alpha);
}

main[data-theme=dark] .component-modal {
    background-color: var(--dark-bg-color-alpha);
}

.component-modal>div.component-modal-container {
    overflow: hidden;
    border-radius: 5px;
    /* padding: 1rem; */
    min-width: 460px;
    max-width: 600px;
    box-shadow: 0 0 .5rem rgba(0, 0, 0, .15) !important;
}

main[data-theme=light] .component-modal>div.component-modal-container {
    background-color: var(--light-bg-color-highlight);
}

main[data-theme=dark] .component-modal>div.component-modal-container {
    background-color: var(--dark-bg-color-highlight);
}


div.component-modal-container.status-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-control.error,
.form-select.error {
    border: 2px solid var(--bs-danger) !important;
}

.form-control.error~span,
.form-select.error~span {
    color: var(--bs-danger) !important;
    font-size: .85rem;
    font-weight: bold;
    display: block;
}


button,
.btn {
    white-space: nowrap;
}



main[data-theme=light] .btn-outline-primary {
    border-width: 2px;
    --bs-btn-color: var(--light-primary-color-light);
    --bs-btn-border-color: var(--light-primary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--light-primary-color);
    --bs-btn-hover-border-color: var(--light-primary-color);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--light-primary-color-light);
    --bs-btn-active-border-color: var(--light-primary-color-light);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--light-primary-color-dark);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--light-primary-color-dark);
    --bs-gradient: none;
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--light-primary-color-alpha) !important;
}

.table {
    /* min-width: 700px !important; */
}

.table th,
.table td {
    white-space: nowrap;
}

.form-check-input:checked {
    background-color: var(--light-primary-color);
    border-color: var(--light-primary-color);
}










/* ------------------------------LAODER------------------------------------------------------ */
.loader {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    color: rgb(var(--primary-color-rgb));
    animation: fill .5s ease-in infinite alternate;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    left: 48px;
    top: 0;
    animation: fill 0.4s ease-in infinite alternate;
}

.loader::after {
    left: auto;
    right: 48px;
    animation-duration: .6s;
}

@keyframes fill {
    0% {
        box-shadow: 0 0 0 2px inset
    }

    100% {
        box-shadow: 0 0 0 10px inset
    }
}

/* ------------------------------------------------------------------------------------- */




/**
 * -------------------  SUCCESS  --------------------------------------------------------
 */


.success-wrapper {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: rgb(var(--primary-color-rgb));
    position: relative;
    opacity: 0;
    transform-origin: center;
    transform: scale(1);
    animation: scale-and-fade .5s cubic-bezier(0.83, 0, 0.17, 1) forwards;
}

@keyframes scale-and-fade {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    60% {
        transform: scale(.4);
        opacity: .6;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.success-wrapper::before,
.success-wrapper::after,
.success-wrapper>span:last-child::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    transform-origin: center;
    animation: splash 1s cubic-bezier(0.25, 1, 0.5, 1) .4s forwards;
}

.success-wrapper::after {
    animation: splash 1s cubic-bezier(0.25, 1, 0.5, 1) .47s forwards;
}

.success-wrapper>span:last-child::before {
    animation: splash 1s cubic-bezier(0.25, 1, 0.5, 1) .54s forwards;
}

@keyframes splash {
    0% {
        transform: scale(1);
        box-shadow: 0 0 5px 0 rgb(var(--primary-color-rgb));
        opacity: .4;
    }

    100% {
        transform: scale(6.3);
        box-shadow: 0 0 15px rgb(var(--primary-color-rgb));
        opacity: .05;
    }
}

.success-wrapper>div:first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 42%;
    height: 23%;
    transform: translate(-50%, -75%) rotate(-35deg);

}

.success-wrapper div :first-child,
.success-wrapper div :last-child {
    display: block;
    border-radius: 900000px;
    width: .27rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
}

.success-wrapper div :last-child {
    height: .27rem;
    width: 100%;
    top: initial;
    bottom: 0;
    transform: translate(2%, 7%);
}




/**
 * -------------------    --------------------------------------------------------
 */




/* ----------------------------------------------------------------------------------- */



svg {
    width: 100px;
    display: block;
    margin: 0 auto 0;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}

svg {
    width: 100px;
    display: block;
    margin: 0 auto 0;
    transform-origin: center;
    transform: scale(.73);
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}

.path.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
}

.path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

p.error {
    color: #D06079;
}

.table-page-buttons button {
    font-size: .78rem;
}



/* CIRCLE PROGRESS BAR */

.box {
    position: relative;
    /* width: 300px;
    height: 400px; */
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 30px 60px rgba(0, 0, 0, .2);
}

.box .percent {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box .percent svg {
    position: relative;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: scale(.9);
}

.box .percent svg circle {
    width: 150px;
    height: 150px;
    fill: none;
    stroke-width: 10;
    stroke: #000;
    transform: translate(5px, 5px);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.box .percent svg circle:nth-child(1) {
    stroke-dashoffset: 0;
    stroke: var(--light-primary-color-alpha);
}

.box .percent svg circle:nth-child(2) {
    stroke: var(--light-primary-color);
    stroke-linecap: round;
    transform-origin: center;
    transform: rotate(90deg) translate(5px, 5px);
}

.box .percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999
}

.box .percent .number h2 {
    font-size: 30px;
}

.box .percent .number h2 span {
    font-size: 20px;
}

.box .text {
    padding: 10px 0 0;
    color: #999;
    font-weight: 700;
    letter-spacing: 1px;
}


@media print {
    .no-print {
        display: none;
    }
}