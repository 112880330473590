.hint {
    display: inline-block !important;
    position: relative;
}

.hint>.popup {
    position: absolute;
    padding: .25rem .5rem;
    left: 50%;
    bottom: 50%;
    z-index: 50;
    opacity: 0;
    transition: transform 120ms cubic-bezier(.23, 1.17, .5, 1.74), opacity 60ms ease;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 5px;
    white-space: nowrap;
    background-color: var(--light-primary-color);
    color: white;
    font-size: .8rem;
}

.hint>.popup:before {
    content: "";
    width: .75rem;
    aspect-ratio: 1;
    border-radius: inherit;
    transform-origin: center;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 33.33%) rotate(45deg);
    z-index: -10;
    position: absolute;
    background-color: inherit;


}

.hint:hover>.popup {
    transform: translate(-50%, -100%);
    opacity: 1;
}